<template>
  <div class="container">
    <div class="flex-container">
      <BlogCard></BlogCard>
      <BlogCard></BlogCard>
    </div>
  </div>
</template>

<script>

import BlogCard from './BlogCard.vue'
export default {
  name: 'BlogCardContainer',
  components: {
    BlogCard
  },
  props: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
