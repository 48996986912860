<template>
      <div class="card">
        <div class="card-image" v-bind:style="tileImage"></div>
        <div class="card-content">
          <h1>The Second City</h1>
          <div class="subtitle">City of Big Shoulders</div>
          <p>Chicago is the home of the blues and the truth of jazz, the heart of comedy and the idea of the skyscraper. It's
            a city with a swagger, but without the surliness or even the fake smiles found in other cities of its size.</p>

          <div class="card-details">
            <div class="card-details-inner">
              <div class="read-more">
                <a class="button" href="https://en.wikivoyage.org/wiki/Chicago">Read Article</a>
              </div>
              <div class="options">
                <div class="comments">
                  <a href="#!">
                    <i class="fa fa-comments-o"></i>
                    16 comments
                  </a>
                </div>
                <div class="likes">
                  <a href="#!">
                    <i class="fa fa-heart-o"></i>
                    322 likes
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>

export default {
  name: 'BlogCard',
  props: { 
  },
  computed: {
    tileImage() {
      return {
        backgroundImage: `url(${require('../assets/IMG_5482.jpg')})`
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
