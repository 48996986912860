<template>
  <nav class="navbar">
    <div class="container">
      <div class="flex">
        <div>
          <a class="brand">
            fba.dev
          </a>
        </div>
        <div class="flex">
          <a href="/services"></a>
          <a href="/blog">articles</a>
          <a href="/tutorials">tutorials</a>
          <a href="/about">about</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  props: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
